import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { Paginated, Device, DeviceListParams } from '~/types/models'

class DevicesModule extends FetchFactory {
  private readonly RESOURCE = Routes.Devices

  async getDevice(deviceId: string) {
    return this.call<Device>({
      method: 'GET',
      url: `${this.RESOURCE.GetDevice(deviceId)}`,
    })
  }

  async getDevices(params: DeviceListParams) {
    return this.call<Paginated<Device>>({
      method: 'GET',
      url: `${this.RESOURCE.GetDevices()}`,
      fetchOptions: {
        params: {
          ...params,
        },
      },
    })
  }
}

export default DevicesModule