import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { ModelMemory } from '~/types/models'

export const useModelMemoriesStore = defineStore('model-memories', () => {
  const { $api } = useNuxtApp()

  const list = ref<ModelMemory[]>([])

  function getList() {
    return $api.modelMemories
      .getModelMemories()
      .then((data) => {
        list.value = data
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    getList,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useModelMemoriesStore, import.meta.hot),
  )
}