import FetchFactory from '../factory'
import Routes from '../routes.client'
import type {
  Paginated,
  Review,
  ReviewsListParams,
  ReviewAddingData,
} from '~/types/models'

class ReviewsModule extends FetchFactory {
  private readonly RESOURCE = Routes.Reviews

  async getReviews({
    page = 1,
    sort_direction = 'desc',
    ...params
  }: ReviewsListParams) {
    return this.call<Paginated<Review>>({
      method: 'GET',
      url: `${this.RESOURCE.GetReviews()}`,
      fetchOptions: {
        params: {
          ...params,
          page,
          sort_direction,
        },
      },
    })
  }

  async addReview(data: ReviewAddingData) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.AddReview()}`,
      body: data,
    })
  }
}

export default ReviewsModule