import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { Promocode } from '~/types/models'

class PromocodesModule extends FetchFactory {
  private readonly RESOURCE = Routes.Promocodes

  async getPromocode(promocode: string) {
    return this.call<Promocode>({
      method: 'GET',
      url: `${this.RESOURCE.GetPromocode(promocode)}`,
    })
  }
}

export default PromocodesModule