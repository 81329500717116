import FetchFactory from '../factory'
import Routes from '../routes.client'
import type {
  OrderData,
  SubscriptionOrder,
  SubscriptionOrderCreationData,
  SubscriptionOrderUpdatingData,
} from '~/types/models'

class SubscriptionOrdersModule extends FetchFactory {
  private readonly RESOURCE = Routes.SubscriptionOrders

  async createSubscriptionOrder(data: SubscriptionOrderCreationData) {
    return this.call<SubscriptionOrder>({
      method: 'POST',
      url: `${this.RESOURCE.CreateSubscriptionOrder()}`,
      body: data,
    })
  }

  async updateSubscriptionOrder(
    orderId: string,
    data: SubscriptionOrderUpdatingData,
  ) {
    return this.call<SubscriptionOrder>({
      method: 'PATCH',
      url: `${this.RESOURCE.UpdateSubscriptionOrder(orderId)}`,
      body: data,
    })
  }

  async getSubscriptionOrder(orderId: string) {
    return this.call<OrderData>({
      method: 'GET',
      url: `${this.RESOURCE.GetSubscriptionOrder(orderId)}`,
    })
  }

  async sendVerificationCode(orderId: string) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.SendVerificationCode()}`,
      body: { OrderId: orderId },
    })
  }

  async verifyVerificationCode(orderId: string, code: string, timezone: number, timeLocal: string) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.VerifyVerificationCode()}`,
      body: { OrderId: orderId, Code: code, Timezone: timezone, TimeLocal: timeLocal },
    })
  }
}

export default SubscriptionOrdersModule