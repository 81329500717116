import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import type {
  Device,
  PaginatedMeta,
  PaginatedLinks,
  DeviceListParams,
} from '~/types/models'

export const useDevicesStore = defineStore('devices', () => {
  const { $api } = useNuxtApp()

  const list = ref<Device[]>([])
  const meta = ref<PaginatedMeta>()
  const links = ref<PaginatedLinks>()

  function getList(params: DeviceListParams) {
    return $api.devices
      .getDevices(params)
      .then((data) => {
        list.value = data.data
        meta.value = data.meta
        links.value = data.links
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    meta,
    links,
    getList,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDevicesStore, import.meta.hot))
}