import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { SeoContent } from '~/types/models'

class SeoContentsModule extends FetchFactory {
  private readonly RESOURCE = Routes.SeoContents

  async getSeoContents(url: string = '/', model_id: string = '') {
    const params = model_id ? { model_id } : { url }

    return this.call<SeoContent[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetSeoContents()}`,
      fetchOptions: {
        params,
      },
    })
  }
}

export default SeoContentsModule