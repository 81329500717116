import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { OrderData, OrderCreationData, CartOrder } from '~/types/models'

class OrdersModule extends FetchFactory {
  private readonly RESOURCE = Routes.Orders

  async createOrder(data: OrderCreationData) {
    return this.call<CartOrder>({
      method: 'POST',
      url: `${this.RESOURCE.CreateOrder()}`,
      body: data,
    })
  }

  async getOrder(orderId: string) {
    return this.call<OrderData>({
      method: 'GET',
      url: `${this.RESOURCE.GetOrder(orderId)}`,
    })
  }

  async sendVerificationCode(orderId: string) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.SendVerificationCode()}`,
      body: { Id: orderId },
    })
  }

  async verifyVerificationCode(orderId: string, code: string) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.VerifyVerificationCode()}`,
      body: { Id: orderId, Code: code },
    })
  }
}

export default OrdersModule