import FetchFactory from '../factory'

class YandexSearchModule extends FetchFactory {
  async search(text: string, page: number) {
    const config = useRuntimeConfig()

    return this.call<{
      documents: []
    }>({
      method: 'GET',
      url: 'v1.0', // TODO: если брать из env-параметров, то ofetch делает так '/v1.0/?', а должно быть так '/v1.0?'
      fetchOptions: {
        params: {
          apikey: config.public.yandexSearchApiKey as string,
          searchid: 6496644,
          text: text,
          per_page: 15,
          page,
        },
      },
      headers: {},
    })
  }
}

export default YandexSearchModule