<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const citiesStore = useCitiesStore()
const serviceCentersStore = useServiceCentersStore()
const modelMemoriesStore = useModelMemoriesStore()
const modelColorsStore = useModelColorsStore()
const salesHitsStore = useSalesHitsStore()

// загружаем список городов
// получаем по ip-адресу город через сервис dadata
// находим текущий город в списке городов по имени
if (!citiesStore.list?.length)
  await useAsyncData('fetchCities', () =>
    citiesStore.getList().then(async () => {
      if (!citiesStore.hasConfirm) await citiesStore.getCurrent()

      return true
    }),
  )

// загружаем сервисные центры города
if (!serviceCentersStore.list?.length)
  await useAsyncData('fetchServiceCenters', () =>
    serviceCentersStore.getList().then(() => true),
  )

// загружаем варианты памяти моделей
if (!modelMemoriesStore.list?.length)
  await useAsyncData('fetchModelMemories', () =>
    modelMemoriesStore.getList().then(() => true),
  )

// загружаем варианты цветов моделей
if (!modelColorsStore.list?.length)
  await useAsyncData('fetchModelColors', () =>
    modelColorsStore.getList().then(() => true),
  )

// загружаем устройства (хиты продаж)
if (!salesHitsStore.list?.length)
  await useAsyncData('fetchSalesHits', () =>
    salesHitsStore.getList().then(() => true),
  )
</script>