export const DEFAULT_CURRENT_CITY = 'Москва'
export const ROUTE_NAMES = {
  HOME: 'home',
  CATALOG_CATEGORIES: 'catalog',
  CATALOG_MODEL_GROUP: 'modelGroupSlug',
  CATALOG_MODEL: 'modelGroupSlug-modelSlug',
  CATALOG_DEVICE: 'modelGroupSlug-modelSlug-id',
  CART: 'cart',
  CONTACTS: 'contacts',
  COMPANY: 'company',
  COMPARISON: 'comparison',
  FAVOURITE: 'favourite',
  NOT_FOUND: 'error',
  THANKS: 'thanks-id',
  TRADE_IN: 'trade-in',
  GUARANTEE: 'guarantee',
  GARANTIA: 'garantia',
  COMPANY_DETAILS: 'company-details',
  PAYMENT: 'payment',
  GARANTIA_SERVICE: 'garantijnoe-obsluzhivanie',
  RETURN: 'return',
  ACCOUNT: 'account',
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_FORM: 'subscription-form',
  SUBSCRIPTION_FORM_FILLED: 'subscription',
  SUBSCRIPTION_DESCRIPTION: 'podpiska',
  SUBSCRIPTION_THANKS: 'subscription-order-accepted-id',
  SUBSCRIPTION_OUT_OF_STOCK: 'out-of-stock',
  SEARCH: 'search-searchQuery',
  REDIRECT: 'redirect',
  PAYMENT_REDIRECT: 'redirect',
  ADVANCED_PAYMENT_REDIRECT: 'redirect',
  SIGNING: 'redirect',
  REVIEWS: 'reviews',
  CONSENT_DOCUMENT: 'documents-consent',
  PARTNER_CREDENTIALS: 'partner-credentials',
}

export const CATALOG_URL_DATA_KEYS = {
  MODEL_GROUP_SLUG: 'c_model_group_slug',
  MODEL_SLUG: 'c_model_slug',
  MODELS_SLUG: 'c_models_slug',
  CONDITION_SLUGS: 'c_condition_slugs',
  ACCUMULATOR_SLUGS: 'c_accumulator_slugs',
  COLOR_SLUGS: 'c_color_slugs',
  MEMORY_SLUGS: 'c_memory_slugs',
  DEVICE_FEATURE: 'c_device_feature',
  SORT_BY: 'c_sort_by',
  MIN_PRICE: 'c_min_price',
  MAX_PRICE: 'c_max_price',
  SUBSCRIPTION: 'c_subscription',
  PAGE: 'c_page',
} as const

export const CATALOG_SORT_BY_KEYS = {
  CHEAP_FIRST: 'cheapFirst',
  POPULAR_FIRST: 'popularFirst',
}

export const CATALOG_FILTER_NAMES = {
  SUBSCRIBE: 'Подписка',
  MEMORY: 'Память',
  COLOR: 'Цвет',
  MODEL: 'Модель',
}

export const DEVICES_SORT_BY = {
  IS_POPULAR: 'IsPopular',
  PRICE: 'Price',
}

export const EMIT_MODAL_FUNCTION_NAMES = {
  OPEN_MODAL_SHOW_CITIES: 'openModalShowCities',
  OPEN_MODAL_SHOW_SERVICE_CENTER: 'openModalShowServiceCenter',
  OPEN_MODAL_SHOW_SERVICE_CENTERS: 'openModalShowServiceCenters',
  OPEN_MODAL_SHOW_BENEFITS_CHECKS: 'openModalShowBenefitsChecks',
  OPEN_MODAL_SHOW_BENEFITS_CONFIDENCE: 'openModalShowBenefitsConfidence',
  OPEN_MODAL_VERIFICATE_PHONE: 'openModalVerificatePhone',
  OPEN_MODAL_SHOW_SPECIFICATIONS: 'openModalShowSpecifications',
  OPEN_MODAL_SUBSCRIPTION_HOW_WORKS: 'openModalSubscriptionHowWorks',
  OPEN_MODAL_SCAN_PASSPORT_DATA: 'openModalScanPassportData',
}

export const STOPPER_COLORS = {
  RED: 'red',
  GREEN: 'green',
  BLUE: 'blue',
  DEEP_BLUE: 'deepblue',
  YELLOW: 'yellow',
  LIGHT_BLUE: 'lightblue',
  VIOLET: 'violet',
  GRAY: 'gray',
} as const

export const PAYMENT_METHODS_KEYS = {
  ONLINE: 'Online',
  DELIVERY: 'OnDelivery',
}

export const PHONE_VERIFICATION_CONTEXT = {
  ORDER: 'order',
  SUBSCRIPTION_ORDER: 'subscriptionOrder',
}

export const MODEL_GROUP_NAME = {
  IPHONE: 'iPhone',
  IPHONE_11: 'iPhone 11',
  IPHONE_12: 'iPhone 12',
  SAMSUNG: 'Samsung',
  HUAWEI: 'Huawei',
  XIAOMI: 'Xiaomi',
}

export const DEVICE_PROPERTY_KEYS = {
  STATE: 'state',
  BATTERY_CAPACITY: 'battery capacity',
  APPLE_ID: 'apple id',
  GARANTEE: 'garantee',
  IMEI: 'imei',
  VERSION: 'version',
  COLOR: 'color',
  MEMORY: 'memory',
  SIM: 'sim',
  ACCESSORIES: 'accessories',
  NFC: 'nfc',
}

export const DEFAULT_ISO_DATE = '0001-01-01T00:00:00+00:00'