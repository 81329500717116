import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { SubscriptionPercent } from '~/types/models'

class SubscriptionPercentsModule extends FetchFactory {
  private readonly RESOURCE = Routes.SubscriptionPercents

  async getSubscriptionPercent() {
    return this.call<{ data: SubscriptionPercent[] }>({
      method: 'GET',
      url: `${this.RESOURCE.GetSubscriptionPercent()}`,
    })
  }
}

export default SubscriptionPercentsModule