import type { App } from 'vue'
import {
  initYandexMetrika as initYM,
  useYandexMetrika as useYM,
} from 'yandex-metrika-vue3'
import type { Router } from 'vue-router'
import { ROUTE_NAMES } from '@/constants'

export const initYandexMetrika = (
  app: App,
  router: Router,
  counterID: string,
) => {
  initYM(app, {
    id: counterID,
    router: router,
    env: ['production', 'staging'].includes(import.meta.env.MODE) ? 'production' : import.meta.env.MODE,
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
    ignoreRoutes: [ROUTE_NAMES.REDIRECT],
    scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
  })
}

const ym = useYM()

export const emitYandexMetrika = (goal: string, params?: object) => {
  ym.reachGoal(goal, params)
}

export const YANDEX_METRIKA_GOALS = {
  /* --------- Шапка (header) сайта START --------- */
  CARD_IN_HEADER: 'card_in_header', // Клик по иконке "Корзина"
  CITY_HEADER: 'city_header', // Клик по городу
  POISK_HEADER: 'poisk_header', // Поиск
  VKATALOG_KATEGORII_HEADER: 'vkatalog_kategorii_header', // Переход в каталог с категориями из навигационного меню
  VESKATALOG_HEADER: 'veskatalog_header', // Клик на пункт навигационного меню "Каталог" (десктопная версия) / "Весь каталог" (мобильная версия)
  /* --------- Шапка (header) сайта END --------- */

  /* --------- Главная START --------- */
  VIDEO: 'video', // Запуск просмотра сторис на главной
  DEVICE: 'device', // Выбор категории устройства для Тrade-In в блоке "Купим Ваш смартфон"
  BANNER0: 'banner0', // Клик по кнопке "Онлайн-калькулятор" в секции Trade-In и по секции в целом
  PODPISKA_GLAVNAYA_CTA: 'podpiska_glavnaya_cta', // Клик на кнопку "Перейти в каталог" в главном баннере
  PODPISKA_GLAVNAYA_CTA_V3: 'podpiska_glavnaya_cta_v3', // Клик на кнопку "Подробнее о подписке" в главном баннере
  GLAVNAYA_CLICK_KARTOCHKA: 'glavnaya_click_kartochka', // Клик на карточку товара в секции "Популярные модели"
  KATEGORIYA_GLAVNAYA: 'kategoriya-glavnaya', // Клик по кнопке в блоке с категориями на главной
  /* --------- Главная END --------- */

  /* --------- Карточка товара START --------- */
  KARTOCHKA: 'kartochka', // Клик по карточке товара
  KARTOCHKA_V_KATALOGE: 'kartochka_v_kataloge', // Клик на мини-карточку на странице каталога (только)
  /* --------- Карточка товара END --------- */

  /* --------- Страница товара START --------- */
  PRODUCT: 'product', // Вход на страницу
  V_KORZINU: 'v_korzinu', // Клик по кнопке "Купить за полную стоимость", которая добавляет товар в корзину
  WATCH_VIDEO: 'watch_video', // Просмотр видео
  PODPISKA_KARTOCHKA_UZNAT_PODROBNEE: 'podpiska_kartochka_uznat_podrobnee', // Клик по кнопке "Подробнее о подписке"
  PODPISKA_KARTOCHKA_ZAKREP_PODROBNEE: 'podpiska_kartochka_zakrep_podrobnee', // Клик по кнопке "Подробнее" в закрепе
  KARTOCHKA_TOVARA_VIDEO: 'kartochka_tovara_video', // В карточке товара есть видео
  VIDEO_O_PODPISKE_NA_STRANICE_TOVARA: 'video_o_podpiske_na_stranice_tovara', // Просмотр видео о подписке
  CARD_MOBILE_PHOTO_ZOOM: 'card_mobile_photo_zoom', // Увеличить изображение в мобилке
  /* --------- Страница товара END --------- */

  /* --------- Trade-In START --------- */
  VISIT_TI: 'visit-ti', // Переход на страницу /trade-in
  CONTACT_TI: 'contact-ti', // Клик по социальным кнопкам в футере (мобильная версия) на странице /trade-in
  /* --------- Trade-In END --------- */

  /* --------- Подписка START --------- */
  PODPISKA_USLOVIYA_PERIOD: 'podpiska_usloviya_period', // Изменение периода подписки
  PODPISKA_USLOVIYA_FAQ_PLATEJI: 'podpiska_usloviya_faq_plateji', // Клик по вопросу "Как вносить платежи"
  PODPISKA_USLOVIYA_FAQ_OBMEN: 'podpiska_usloviya_faq_obmen', // Клик по вопросу "Обмен на другую модель"
  PODPISKA_USLOVIYA_FAQ_GARANTIYA: 'podpiska_usloviya_faq_garantiya', // Клик по вопросу "Гарантия и возврат"
  PODPISKA_USLOVIYA_FAQ_VIKUP: 'podpiska_usloviya_faq_vikup', // Клик по вопросу "Полный выкуп устройства"
  PODPISKA_USLOVIYA_FAQ_DANNIE: 'podpiska_usloviya_faq_dannie', // Клик по вопросу "Как перенести данные"
  PODPISKA_USLOVIYA_FAQ_PODPISKA_LUCHSHE: 'podpiska_usloviya_faq_podpiska_luchshe', // Клик по вопросу "Почему подписка лучше рассрочки"
  PODPISKA_USLOVIYA_KONTAKT: 'podpiska_usloviya_kontakt', // Создание заявки в первой форме
  PODPISKA_ZAYAVKA_NOMER: 'podpiska_zayavka_nomer', // Обновление заявки во второй форме
  PODPISKA_USLOVIYA_ADDRESS: 'podpiska_usloviya_address',
  PODPISKA_ZAYAVKA_ZAPOLNIL_ZAYAVKU: 'podpiska_zayavka_zapolnil_zayavku', // Заполнил форму заявки и ожидает подтверждения по смс
  PODPISKA_ZAYAVKA_PODTVERJDENIE: 'podpiska_zayavka_podtverjdenie', // Подтверждение заявки по результату сохранения паспортных данных
  PODPISKA_RECOMMEND_CLICK: 'podpiska_recommend_click', // Клик по рекомендованному товару
  PODPISKA_KORZINA: 'podpiska-korzina', // Переход на подписку
  /* --------- Подписка END --------- */

  /* --------- Каталог START --------- */
  CATALOG_IPHONE: 'catalog_iphone', // Посетил каталог iPhone, сделав клик по пункту навигационного меню в шапке
  CATALOG_IPHONE11: 'catalog_iphone11', // Посетил каталог iPhone 11, сделав клик по пункту фильтра на странице каталога товаров
  CATALOG_IPHONE12: 'catalog_iphone12', // Посетил каталог iPhone 12, сделав клик по пункту фильтра на странице каталога товаров
  CATALOG_IPHONE_IZ_FOOTERA: 'catalog_iphone_iz_footera', // Посетил каталог iPhone, сделав клик в меню футера
  CATALOG_SAMSUNG_IZ_SHAPKI: 'catalog_samsung_iz_shapki', // Посетил каталог Samsung, сделав клик по пункту навигационного меню в шапке
  CATALOG_XIAOMI_IZ_SHAPKI: 'catalog_xiaomi_iz_shapki', // Посетил каталог Xiaomi, сделав клик по пункту навигационного меню в шапке
  CATALOG_HUAWEI_IZ_SHAPKI: 'catalog_huawei_iz_shapki', // Посетил каталог Huawei, сделав клик по пункту навигационного меню в шапке
  CATALOG_NOTICED_OUT_OF_STOCK: 'catalog_noticed_out_of_stock', // Увидел заглушку "Эта модель полностью распродана"
  CATALOG_MEMORY_FILTER: 'catalog_memory_filter', // Клик по значению фильтра "Память" в каталоге
  CATALOG_COLOR_FILTER: 'catalog_color_filter', // Клик по значению фильтра "Цвет" в каталоге
  /* --------- Каталог END --------- */

  /* --------- Корзина START --------- */
  KORZINA_KUPLENO: 'korzina_kupleno', // Устройство куплено
  KORZINA_PRICE_CHANGED: 'korzina_price_changed', // Цена изменилась
  VIBOR_SC: 'vibor_sc', // Клик по выбору сервисного центра
  SPOSOB_OPLATI: 'sposob_oplati', // Клик по выбору способа оплаты
  PEREJTI_K_OPLATE: 'Perejti_k_oplate', // Клик по кнопке "Перейти к оплате"
  PODTVERDIL_SMS: 'Podtverdil_SMS', // Подтвердил СМС
  OPLATA_ONLINE_V_KORZINE: 'oplata-online-v-korzine', // Оплата онлайн
  OPLATA_POLUCHENIE_KORZINA: 'oplata-poluchenie-korzina', // Оплата при получении
  /* --------- Корзина END --------- */

  /* --------- Новые START --------- */
  SOCIAL_MEDIA_LINK_CLICK: 'social_media_link_click', // Клик на соцсети
  CONTACT: 'contact', // Клик/тап по номеру телефона
  CLICK_PRODUCT_CARD_HITS_SECTION: 'click_product_card_hits_section', // Клик по карточке товара в секции "Хиты продаж"
  CLICK_SOCIAL_PHONE_ONLINE_BLOCK: 'click_social_phone_online_block', // Клик по социальным кнопкам и телефону в блоке "Мы онлайн!"
  CLICK_CATEGORY_IMAGE_IN_CATALOG_PAGE: 'click_category_image_in_catalog_page', // Клик по изображению категории на странице /catalog
  CARD_COLOR_SELECTION_CLICK: 'card_color_selection_click', // Клик, выбор другого цвета в карточке товара
  CARD_MEMORY_SELECTION_CLICK: 'card_memory_selection_click', // Клик, выбор другого объема памяти в карточке товара
  VYBR_USTROYSTVA_ZAGLUSHKA: 'vybr_ustroystva_zaglushka', // Клик по кнопке "Выбрать другое уст-во" на заглушке
  BANNER_ABOVE_HEADER_CLICK: 'banner_above_header_click', // Клик на баннер трейд-ин над шапкой
  CARD_SUBSCRIPTION_MORE_LINK_CLICKED: 'card_subscription_more_link_clicked', // Клик на "подробнее о подписке" в карточке товара
  PAGE_NOT_FOUND: 'page_not_found', // Попал на страницу 404
  PODROBNEE_O_PODPISKE: 'podrobnee_o_podpiske', // Клик на "подробнее о подписке" в карточке товара  /podpiska
  MODEL_SLIDER_CLICKED: 'model_slider_clicked', // Клик на модель в баннере на главной
  TI_TG_BOT: 'ti_tg_bot', // Клик на кнопку "Продать смартфон" на главной
  /* --------- Новые END --------- */
}