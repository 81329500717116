import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { PaymentRedirect } from '~/types/models'

class PaymentRedirectsModule extends FetchFactory {
  private readonly RESOURCE = Routes.PaymentRedirects

  async getPaymentRedirect(slug: string, isAdvance: boolean) {
    return this.call<PaymentRedirect>({
      method: 'GET',
      url: `${this.RESOURCE.GetPaymentRedirect(slug)}`,
      fetchOptions: {
        params: {
          advancePayment: isAdvance,
          earlyCancel: false,
          withoutFine: true,
        },
      },
    })
  }
}

export default PaymentRedirectsModule