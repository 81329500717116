import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { ModelColor, ModelColorListParams } from '~/types/models'

class ModelColorsModule extends FetchFactory {
  private readonly RESOURCE = Routes.ModelColors

  async getModelColors(params?: ModelColorListParams) {
    return this.call<ModelColor[]>(
      {
        method: 'GET', url: `${this.RESOURCE.GetModelColors()}`,
        fetchOptions: {
          params,
        },
      },
    )
  }
}

export default ModelColorsModule