import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { ServiceCenter } from '~/types/models'

class ServiceCentersModule extends FetchFactory {
  private readonly RESOURCE = Routes.ServiceCenters

  /**
   * Return the service centers as array
   * @returns
   */
  async getServiceCenters(city_id: string) {
    return this.call<ServiceCenter[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetServiceCenters()}`,
      fetchOptions: { params: { city_id } },
    })
  }
}

export default ServiceCentersModule