import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { ModelMemory, ModelMemoryListParams } from '~/types/models'

class ModelMemoriesModule extends FetchFactory {
  private readonly RESOURCE = Routes.ModelMemories

  async getModelMemories(params?: ModelMemoryListParams) {
    return this.call<ModelMemory[]>(
      {
        method: 'GET', url: `${this.RESOURCE.GetModelMemories()}`,
        fetchOptions: {
          params,
        },
      },
    )
  }
}

export default ModelMemoriesModule