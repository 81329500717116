import { $fetch, type FetchOptions } from 'ofetch'
import { defineNuxtPlugin } from '#app'
import YandexSearchModule from '~/repository/modules/yandex-search.module'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const fetchOptions: FetchOptions = {
    baseURL: config.public.yandexSearchApiURL as string,
  }

  /** create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions)

  return {
    provide: {
      yandexSearchApi: new YandexSearchModule(apiFetcher),
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $yandexSearchApi: YandexSearchModule
  }
}