<template>
  <div
    :class="[
      isSubscriptionPage
        ? sectionSubscriptionDescriptionWrapperStyles
        : cx(sectionStyles, 'relative', 'sm:!mt-0'),
      'container',
    ]"
  >
    <div
      v-if="hasSubtitle"
      :class="sectionSubscriptionDescriptionSubtitleStyles"
    >
      Pedant.Market рекомендует
    </div>
    <div
      v-if="hasTitle"
      :class="[sectionTitleStyles, isSubscriptionPage ? '!text-center' : '']"
    >
      Популярные модели iPhone
      <br v-if="isSubscriptionPage" class="xl:hidden">
      по подписке
    </div>
    <div
      v-if="isShowPopularCategories"
      :class="classes.popularCategoriesWrapper"
    >
      <div :class="classes.popularCategoriesList">
        <BaseButton
          :class="[
            classes.popularCategoriesItem,
            classes.popularCategoriesItemActive,
          ]"
        >
          Все модели
        </BaseButton>
        <BaseButton
          v-for="(model, index) in models"
          :key="index"
          :class="[classes.popularCategoriesItem, classes.popularCategoriesItemGrid]"
          @click="redirect(model.url)"
        >
          {{ model.name }}
          <span
            v-if="model.count >= 100"
            :class="classes.popularCategoriesItemHit"
          >
            хит
          </span>
        </BaseButton>
      </div>
    </div>
    <div
      v-if="!devicesInRows?.length"
      :class="[classes.grid, { '!mt-0': isSubscriptionPage }]"
    >
      <DeviceCardSkeleton v-for="number in itemsInRows" :key="number" />
    </div>
    <div
      v-else
      :class="[
        classes.grid,
        rows === 1 ? classes.gridOneRow : classes.gridTwoRows,
        { '!mt-0': isSubscriptionPage },
      ]"
    >
      <DeviceCard
        v-for="(device, key) in devicesInRows"
        :key="key"
        :device="device"
        @click="
          emitYandexMetrika(YANDEX_METRIKA_GOALS.GLAVNAYA_CLICK_KARTOCHKA)
        "
      />
    </div>

    <BaseButton
      :intent="!isPageOutOfStock ? 'base-primary' : 'base-secondary'"
      size="small"
      type="nuxt-link"
      url="/phone-i"
      :class="['mx-auto', !isPageOutOfStock ? 'xl:w-[255px]' : 'w-full']"
    >
      Смотреть все
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { DEVICES_SORT_BY, ROUTE_NAMES } from '@/constants'
import { sectionSubscriptionDescriptionSubtitleStyles, sectionSubscriptionDescriptionWrapperStyles, sectionStyles, sectionTitleStyles, emitYandexMetrika, YANDEX_METRIKA_GOALS } from '@/utils'

const props = defineProps({
  isSubscriptionPage: {
    type: Boolean,
    default: false,
    required: false,
  },
  hasTitle: {
    type: Boolean,
    default: true,
    required: false,
  },
  hasSubtitle: {
    type: Boolean,
    default: true,
    required: false,
  },
  isShowPopularCategories: {
    type: Boolean,
    default: false,
    required: false,
  },
  rows: {
    type: Number,
    default: 2,
    required: false,
  },
  isPageOutOfStock: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const classes = {
  popularCategoriesWrapper: cx([
    'overflow-x-scroll',
    'py-2.5',
    'xl:overflow-x-visible',
  ]),
  popularCategoriesList: cx([
    'flex',
    'w-max',
    'gap-1',
    'xl:flex-wrap',
    'xl:gap-4',
    'sm:w-auto',
  ]),
  popularCategoriesItem: cx([
    '!py-1.5',
    '!px-3',
    'rounded-[70px]',
    'text-secondary',
    'hover:text-secondary-on-color',
    'active:text-secondary-on-color',
    'transition',
    'duration-200',
    'whitespace-nowrap',
    'text-[14px]',
    'xl:text-[16px]',
    'bg-bg-main-thirdly',
    'hover:bg-bg-blue-accent-hover',
    'active:bg-bg-blue-accent-click',
  ]),
  popularCategoriesItemActive: cx([
    '!bg-bg-blue-accent',
    '!text-primary-on-color',
    'cursor-not-allowed',
  ]),
  popularCategoriesItemGrid: cx([
    'grid',
    'gap-1',
    'grid-cols-[repeat(2,_auto)]',
    'items-center',
  ]),
  popularCategoriesItemHit: cx([
    'py-1',
    'px-3',
    'bg-bg-red-error',
    'text-primary-on-color',
    'rounded-2xl',
    'text-[10px]/[13px]',
    'font-bold',
    'uppercase',
    'flex',
    'items-center',
    'justify-between',
  ]),
  grid: cx([
    'grid',
    'gap-x-2',
    'gap-y-3',
    'grid-cols-2',
    'my-6',
    'mx-0',
    '[&>*:nth-child(2n)]:ml-auto',
    'xl:gap-[21px]',
    'xl:grid-cols-3',
    'xl:[&>*:nth-child(4n)]:mx-auto',
    'xl:[&>*:nth-child(5n)]:mr-auto',
    'xl:grid-cols-5',
    'xl:my-9',
  ]),
  gridOneRow: cx([
    '[&>*:nth-child(3n)]:hidden',
    '[&>*:nth-child(4n)]:hidden',
    '[&>*:nth-child(5n)]:hidden',
    'xl:[&>*:nth-child(3n)]:flex',
    'xl:[&>*:nth-child(4n)]:flex',
    'xl:[&>*:nth-child(5n)]:flex',
  ]),
  gridTwoRows: cx([
    '[&>*:nth-child(n+7)]:hidden',
    'xl:[&>*:nth-child(n+7)]:flex',
  ]),
}

const fetchPopularDevices = async () => {
  await useAsyncData('fetchDevices', () =>
    devicesStore
      .getList({
        SortBy: DEVICES_SORT_BY.IS_POPULAR,
        SortDirection: 'desc',
        IsNew: 0,
        ParentId: '21876e69-9d87-11e5-9312-f452145fc090',
      })
      .then(() => true),
  )
}

const devicesStore = useDevicesStore()
if (!devicesStore.list?.length) await fetchPopularDevices()

const { list: devices } = storeToRefs(devicesStore)

const salesHitsStore = useSalesHitsStore()
const { list: models } = storeToRefs(salesHitsStore)

const ITEMS_IN_ROW = 5
const itemsInRows = computed(() => props.rows * ITEMS_IN_ROW)

const devicesInRows = computed(() => devices.value?.slice(0, itemsInRows.value))

const redirect = async (url: string) => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.KATEGORIYA_GLAVNAYA)

  const newUrl = url.startsWith('/') ? url.slice(1) : url // remove first '/'
  const [modelGroupSlug, modelSlug] = newUrl.split('/')

  await navigateTo({
    name: ROUTE_NAMES.CATALOG_MODEL,
    params: {
      modelGroupSlug,
      modelSlug,
    },
    query: { c_subscription: '1' },
  })
}
</script>