import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { ModelColor } from '~/types/models'

export const useModelColorsStore = defineStore('model-colors', () => {
  const { $api } = useNuxtApp()

  const list = ref<ModelColor[]>([])

  function getList() {
    return $api.modelColors
      .getModelColors()
      .then((data) => {
        list.value = data
      })
      .catch(() => {
        list.value = []
      })
  }

  return {
    list,
    getList,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModelColorsStore, import.meta.hot))
}