<template>
  <BaseButton
    :title="!isComparableDevice ? 'Добавить товар в сравнение': 'Убрать товар из сравнения'"
    :class="[classes.root, isComparableDevice ? classes.active : '']"
    @click="userStore.toggleComparableDevice(props.device)"
  >
    <Icon
      name="common:comparison-white"
      size="18"
    />
  </BaseButton>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue'
import { cx } from 'cva'
import type { Device } from '~/types/models'

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default() {
      return {}
    },
    required: true,
  },
})

const classes = {
  root: cx([
    'bg-[rgb(100_100_100_/_20%)]',
    'rounded-3xl',
    '!w-[28px]',
    'h-[28px]',
    'mb-1',
    'flex',
    'items-center',
    'justify-center',
    'cursor-pointer',
    'transition',
    'delay-500',
    'hover:opacity-80',
  ]),
  active: 'bg-bg-red-error',
}

const userStore = useUserStore()

const isComparableDevice = computed((): boolean => userStore.isComparableDevice(props.device))
</script>