import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_hAQZccAnsC from "/app/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_4F1kZh3YAB from "/app/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import nuxt3_plugin_BdfRvWAAOY from "/app/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import floating_vue_EIcJ7xiw0h from "/app/.nuxt/floating-vue.mjs";
import plugin_PSbK9A48ZQ from "/app/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import dadataApi_B0Hjv1rFhO from "/app/plugins/dadataApi.ts";
import datepicker_M6AvH84RFc from "/app/plugins/datepicker.ts";
import glightbox_client_zl8nhiNuiw from "/app/plugins/glightbox.client.ts";
import lazy_load_Ii6vFyPvsK from "/app/plugins/lazy-load.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
import maskito_gWSZWXIwnW from "/app/plugins/maskito.ts";
import pinia_shared_state_MMy7vjuo4c from "/app/plugins/pinia-shared-state.ts";
import plyr_client_AWuhaKXXrg from "/app/plugins/plyr.client.ts";
import sweet_scroll_nt81r82393 from "/app/plugins/sweet-scroll.ts";
import vee_validate_KcKlKmvCrJ from "/app/plugins/vee-validate.ts";
import vue_final_modal_pML93k5qcp from "/app/plugins/vue-final-modal.ts";
import yandex_metrika_client_MiNHrQhx1f from "/app/plugins/yandex-metrika.client.ts";
import yandex_search_api_OCouY9udOo from "/app/plugins/yandex-search-api.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  plugin_client_4F1kZh3YAB,
  nuxt3_plugin_BdfRvWAAOY,
  floating_vue_EIcJ7xiw0h,
  plugin_PSbK9A48ZQ,
  api_GFfDXud5Cr,
  dadataApi_B0Hjv1rFhO,
  datepicker_M6AvH84RFc,
  glightbox_client_zl8nhiNuiw,
  lazy_load_Ii6vFyPvsK,
  maska_UHaKf2z1iQ,
  maskito_gWSZWXIwnW,
  pinia_shared_state_MMy7vjuo4c,
  plyr_client_AWuhaKXXrg,
  sweet_scroll_nt81r82393,
  vee_validate_KcKlKmvCrJ,
  vue_final_modal_pML93k5qcp,
  yandex_metrika_client_MiNHrQhx1f,
  yandex_search_api_OCouY9udOo
]