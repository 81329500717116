import { defineRule } from 'vee-validate'
import { required, alpha_spaces } from '@vee-validate/rules'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      VeeValidate: {
        defineRule,
        rules: {
          required,
          alpha_spaces,
        },
      },
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $VeeValidate: {
      defineRule: typeof defineRule
      rules: {
        required: typeof required
        alpha_spaces: typeof alpha_spaces
      }
    }
  }
}