import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { City } from '~/types/models'

class CitiesModule extends FetchFactory {
  private readonly RESOURCE = Routes.Cities

  async getCities() {
    return this.call<City[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetCities()}`,
    })
  }
}

export default CitiesModule