import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { Redirect } from '~/types/models'

class RedirectsModule extends FetchFactory {
  private readonly RESOURCE = Routes.Redirects

  async getRedirect(slug: string) {
    return this.call<Redirect>({
      method: 'GET',
      url: `${this.RESOURCE.GetRedirect(slug)}`,
    })
  }
}

export default RedirectsModule