import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { SalesHits, SalesHitsParams } from '~/types/models'

class SalesHitsModule extends FetchFactory {
  private readonly RESOURCE = Routes.SalesHits

  async getSalesHits(params: SalesHitsParams) {
    return this.call<SalesHits[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetSalesHits()}`,
      fetchOptions: {
        params: {
          ...params,
        },
      },
    })
  }
}

export default SalesHitsModule