import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

import { useCitiesStore } from './cities'
import type { ServiceCenter } from '~/types/models'

export const useServiceCentersStore = defineStore('service-centers', () => {
  const { $api } = useNuxtApp()

  const current = ref<ServiceCenter>()
  const shownInModal = ref<ServiceCenter>()
  const list = ref<ServiceCenter[]>([])
  const cities = useCitiesStore()

  async function getList() {
    if (!cities.current?.id) return

    $api.serviceCenters
      .getServiceCenters(cities.current.id)
      .then(data => (list.value = data))
      .catch(() => (list.value = []))
  }

  return { current, shownInModal, list, getList }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useServiceCentersStore, import.meta.hot),
  )
}