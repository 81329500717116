<template>
  <component
    :is="component"
    :class="button({ intent, size, underline })"
    :title="title ? title : null"
    :type="inputType ? inputType : null"
    :value="value ? value : null"
    :href="finalHref ? finalHref : null"
    :target="target ? target : null"
    :aria-label="ariaLabel ? ariaLabel : null"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { cx, cva, type VariantProps } from 'cva'

const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  intent: 'default',
  size: 'default',
  underline: 'off',
  title: '',
  url: '',
  inputType: '',
  value: '',
  href: '',
  target: '',
  ariaLabel: '',
})

/* --------- STYLES START --------- */
const baseStyle = cx([
  'relative',
  'w-full',
  'flex',
  'items-center',
  'box-border',
  'rounded-xl',
  'leading-none',
  'duration-300',
  'cursor-pointer',
  'transition',
])

const basePrimaryStyle = cx([
  baseStyle,
  'font-semibold',
  'text-primary',
  'bg-bg-yellow-yellow',
  'hover:bg-bg-yellow-yellow-hover',
  'group-hover:bg-bg-yellow-yellow-hover',
  'active:bg-bg-yellow-yellow-click',
  'disabled:text-disable',
  'disabled:bg-bg-dark-blue-disable',
  'justify-center',
])

const baseSecondaryStyle = cx([
  baseStyle,
  'font-semibold',
  'text-accent',
  'bg-bg-main-thirdly',
  'hover:bg-bg-blue-hover',
  'active:bg-bg-blue-click',
  'disabled:text-disable',
  'disabled:bg-bg-dark-blue-disable',
  'justify-center',
])

const baseRedStyle = cx([
  baseStyle,
  'font-semibold',
  'text-primary',
  'bg-[#F14700]',
  'hover:bg-[#D13E00]',
  'active:bg-[#B33500]',
  'disabled:text-disable',
  'justify-center',
])

const baseBlue = cx([
  baseStyle,
  'bg-bg-blue-accent',
  'justify-center',
  'hover:bg-bg-blue-accent-hover',
  'active:bg-bg-blue-accent-click',
  'disabled:text-disable',
  'disabled:bg-bg-dark-blue-disable',
])

const linkStyle = cx([
  baseStyle,
  'text-accent',
  'hover:text-accent-hover',
  'active:text-accent-click',
  'disabled:text-disable-on-color',
])

const linkOnColorPrimaryStyle = cx([
  baseStyle,
  'text-primary-on-color',
  'hover:text-yellow',
  'active:text-yellow-hover',
  'disabled:text-disable-on-color',
])

const linkOnColorSecondaryStyle = cx([
  baseStyle,
  'text-secondary-on-color',
  'hover:text-primary-on-color',
  'active:text-primary-on-color',
  'disabled:text-disable-on-color',
])

const button = cva([], {
  variants: {
    intent: {
      default: ['w-auto', 'overflow-visible', 'text-left'],
      ['base-primary']: basePrimaryStyle,
      ['base-secondary']: baseSecondaryStyle,
      ['base-red']: baseRedStyle,
      ['base-blue']: baseBlue,
      ['link']: linkStyle,
      ['link-on-color-primary']: linkOnColorPrimaryStyle,
      ['link-on-color-secondary']: linkOnColorSecondaryStyle,
    },
    size: {
      default: ['p-0'],
      middle: ['py-4', 'px-6'],
      small: ['py-2', 'px-4'],
    },
    underline: {
      off: [],
      on: ['underline', 'underline-offset-4'],
    },
  },
})

type ButtonProps = VariantProps<typeof button>
/* --------- STYLES END --------- */

interface Props {
  type?: string // input | a | nuxt-link | button
  intent?: ButtonProps['intent']
  size?: ButtonProps['size']
  underline?: ButtonProps['underline']
  title?: string
  url?: string | { name: string } // for nuxt-link
  inputType?: string // submit; for input
  value?: string // for input
  href?: string // for link
  target?: string // for link
  ariaLabel?: string // for link
}

const router = useRouter()

const finalHref = computed(() => {
  if (props.type === 'nuxt-link') {
    if (typeof props.url === 'object' && props.url.name) {
      const route = router.resolve({
        name: props.url.name,
      })

      return route.href
    }

    return props.url
  }

  return props.href
})

const component = computed(() => {
  if (props.type === 'nuxt-link') return defineNuxtLink({})

  return props.type
})
</script>