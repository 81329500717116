import SweetScroll from 'sweet-scroll'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      SweetScroll,
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $SweetScroll: typeof SweetScroll
  }
}