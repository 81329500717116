import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { Model, ModelsListParams } from '~/types/models'

class ModelsModule extends FetchFactory {
  private readonly RESOURCE = Routes.Model

  async getModel({ group, model }: ModelsListParams) {
    return this.call<Model>({
      method: 'GET',
      url: `${this.RESOURCE.GetModel({ group, model })}`,
    })
  }

  async getModels(group: string) {
    return this.call<Model[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetModels(group)}`,
    })
  }
}

export default ModelsModule