import { $fetch, type FetchOptions } from 'ofetch'
import { defineNuxtPlugin } from '#app'
import DadataModule from '~/repository/modules/dadata.module'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const fetchOptions: FetchOptions = {
    baseURL: config.public.dadataApiURL as string,
    headers: {
      Authorization: `Token ${config.public.dadataApiToken}`,
    },
  }

  /** create a new instance of $fetcher with custom option */
  const apiFetcher = $fetch.create(fetchOptions)

  return {
    provide: {
      dadataApi: new DadataModule(apiFetcher),
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $dadataApi: DadataModule
  }
}