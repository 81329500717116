import { PiniaSharedState } from 'pinia-shared-state'
import type { Pinia } from 'pinia'

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.browser) {
    (nuxtApp.vueApp.$nuxt.$pinia as Pinia).use(
      PiniaSharedState({
        enable: true,
        initialize: true,
        type: 'localstorage',
      }),
    )
  }
})