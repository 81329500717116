import type { ModelsListParams } from '~/types/models'

const Routes = {
  Cities: {
    GetCities: () => '/cities',
  },
  CustomerPassports: {
    GetCustomerPassport: (subscriptionOrderId: string) =>
      `/customer-passports/get-by-subscription-order/${subscriptionOrderId}`,
    SaveCustomerPassport: () => '/customer-passports',
    VerifyCustomerPassport: () => '/customer-passports/verify',
    RecognizeCustomerPassport: () => '/customer-passports/recognize',
  },
  Dadata: {
    GetAddressByIp: () => '/rs/iplocate/address',
    GetFullName: () => '/rs/suggest/fio',
    GetAddress: () => '/rs/suggest/address',
    GetIssuedBy: () => '/rs/suggest/fms_unit',
  },
  Devices: {
    GetDevice: (deviceId: string) => `/devices/${deviceId}`,
    GetDevices: () => '/devices',
  },
  Model: {
    GetModel: ({ group, model }: ModelsListParams) =>
      `/models/${group}/${model}`,
    GetModels: (group: string) => `/models/${group}`,
  },
  ModelColors: {
    GetModelColors: () => '/model-colors',
  },
  ModelGroups: {
    GetModelGroup: (slug: string) => `/model-groups/${slug}`,
    GetModelGroups: () => '/model-groups',
  },
  ModelMemories: {
    GetModelMemories: () => '/model-memories',
  },
  Orders: {
    GetOrder: (orderId: string) => `/orders/${orderId}`,
    CreateOrder: () => '/orders',
    SendVerificationCode: () => '/orders/send-verification-code',
    VerifyVerificationCode: () => '/orders/verify-verification-code',
  },
  Organizations: {
    GetOrganiizations: () => '/organizations',
  },
  PaymentRedirects: {
    GetPaymentRedirect: (slug: string) => `/subscription-plans/${slug}`,
  },
  Promocodes: {
    GetPromocode: (promocode: string) => `/promocodes/${promocode}`,
  },
  Redirects: {
    GetRedirect: (slug: string) => `/redirects/${slug}`,
  },
  Reviews: {
    GetReviews: () => '/reviews',
    AddReview: () => '/reviews',
  },
  SalesHits: {
    GetSalesHits: () => '/models',
  },
  SeoContents: {
    GetSeoContents: () => '/seo-contents',
  },
  ServiceCenters: {
    GetServiceCenters: () => '/service-centers',
  },
  SubscriptionOrders: {
    GetSubscriptionOrder: (orderId: string) =>
      `/subscription-orders/${orderId}`,
    CreateSubscriptionOrder: () => '/subscription-orders',
    UpdateSubscriptionOrder: (orderId: string) =>
      `/subscription-orders/${orderId}`,
    SendVerificationCode: () => '/subscription-orders/send-verification-code',
    VerifyVerificationCode: () =>
      '/subscription-orders/verify-verification-code',
  },
  SubscriptionPercents: {
    GetSubscriptionPercent: () => '/subscription-percents',
  },
  Tinkoff: {
    CreateOrder: () => '/tinkoff/create-url',
  },
  Yandex: {
    Search: () => '',
  },
}

export default Routes