import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import _ from 'lodash'
import type {
  Device,
  DevicesComparisonTableItem,
  DevicesComparisonTableItemField,
} from '../types/models'

export const useUserStore = defineStore('user', {
  state: () => ({
    viewedDevices: ref<Device[]>([]),
    favouriteDevices: ref<Device[]>([]),
    comparableDevices: ref<Device[]>([]),
    isShowTradeInPreview: ref<boolean>(true),
  }),
  getters: {
    viewedDevicesIds: state =>
      state.viewedDevices.map(viewedDevice => viewedDevice.Id),
    favouriteDevicesIds: state =>
      state.favouriteDevices.map(favouriteDevice => favouriteDevice.Id),
    comparableDevicesIds: state =>
      state.comparableDevices.map(comparableDevice => comparableDevice.Id),
  },
  actions: {
    addViewedDevice(device: Device) {
      const isExist
        = device
        && this.viewedDevices.findIndex(
          viewedDevice => viewedDevice.Id === device.Id,
        ) !== -1
      if (isExist) return

      this.viewedDevices.push(device)
    },
    toggleFavouriteDevice(device: Device) {
      const isExist
        = this.favouriteDevices.findIndex(
          favouriteDevice => favouriteDevice.Id === device.Id,
        ) !== -1
      if (isExist) {
        // remove favourite device
        this.favouriteDevices = this.favouriteDevices.filter(
          favouriteDevice => favouriteDevice.Id !== device.Id,
        )
      }
      else {
        // add favourite device
        this.favouriteDevices.push(device)
      }
    },
    isFavouriteDevice(device: Device): boolean {
      return this.favouriteDevicesIds.includes(device.Id)
    },
    toggleComparableDevice(device: Device) {
      const isExist
        = this.comparableDevices.findIndex(
          comparableDevice => comparableDevice.Id === device.Id,
        ) !== -1
      if (isExist) {
        // remove comparable device
        this.comparableDevices = this.comparableDevices.filter(
          comparableDevice => comparableDevice.Id !== device.Id,
        )
      }
      else {
        // add comparable device
        this.comparableDevices.push(device)
      }
    },
    isComparableDevice(device: Device): boolean {
      return this.comparableDevicesIds.includes(device.Id)
    },
    getDevicesComparisonTable() {
      let table = Array<DevicesComparisonTableItem>()

      this.comparableDevices.forEach((comparableDevice) => {
        table.push({
          peculiarities:
            getDevicesComparisonTableItemPeculiarities(comparableDevice),
          characteristics:
            getDevicesComparisonTableItemCharacteristics(comparableDevice),
          attributes: _.cloneDeep(comparableDevice.Attributes),
        })
      })

      table = setDashesForMissingTableFields(table)
      table.forEach((tableItem) => {
        for (const key in tableItem) {
          table = sortTableFields(table, key)
        }
      })

      return table
    },
    getDevicesComparisonTableWithDifference() {
      let tableDifferences = this.getDevicesComparisonTable()

      tableDifferences.forEach((item) => {
        for (const key in item) {
          tableDifferences = getAttributesComparison(tableDifferences, key)
        }
      })

      tableDifferences.forEach((item) => {
        for (const key in item) {
          tableDifferences = sortTableFields(tableDifferences, key)
        }
      })

      return tableDifferences
    },
  },
  persist: {
    pick: [
      'viewedDevices',
      'favouriteDevices',
      'comparableDevices',
      'isShowTradeInPreview',
    ],
  },
  share: {
    // An array of fields that the plugin will ignore.
    omit: ['isShowTradeInPreview'],
    // Override global config for this store.
    enable: true,
    initialize: true,
  },
})

const getDevicesComparisonTableItemPeculiarities = (device: Device) => {
  return [
    {
      name: 'Состояние',
      value: device.ModelCondition.Name,
    },
    {
      name: 'Ёмкость аккумулятора',
      value: String(device.BatteryVolume),
    },
    {
      name: 'Гарантия:',
      value: device.BasicPrice > 0 ? 'На весь срок подписки' : 'Pedant.ru 1 год',
    },
  ]
}

const getDevicesComparisonTableItemCharacteristics = (device: Device) => {
  return [
    {
      name: 'Версия',
      value: device.Version ? device.Version : '-',
    },
    {
      name: 'Цвет',
      value: device.Color.Name,
    },
    {
      name: 'Память',
      value: device.ModelMemory.Name,
    },
    {
      name: 'Комплектация',
      value: 'Коробка Pedant.Market',
    },
    {
      name: 'Датчик NFC',
      value: 'Исправен',
    },
  ]
}

const setDashesForMissingTableFields = (
  table: Array<DevicesComparisonTableItem>,
) => {
  const newTable: Array<DevicesComparisonTableItem> = _.cloneDeep(table)

  const tableFields: Array<string> = []
  newTable.forEach((tableItem) => {
    tableItem.attributes.forEach((attribute) => {
      tableFields.push(attribute.name)
    })
  })

  const uniqueTableFields: Set<string> = new Set(tableFields)
  newTable.forEach((tableItem) => {
    uniqueTableFields.forEach((item) => {
      const desired = tableItem.attributes.find(
        attribute => attribute.name === item,
      )
      if (!desired) {
        tableItem.attributes.push({
          name: item,
          value: '-',
        })
      }
    })
  })

  return newTable
}

const getAttributesComparison = (
  table: Array<DevicesComparisonTableItem>,
  field: string,
) => {
  const newTable: Array<DevicesComparisonTableItem> = _.cloneDeep(table)

  const attributesNames: Array<string> = []
  newTable.forEach((device) => {
    device[field].forEach((attribute) => {
      attributesNames.push(attribute.name)
    })
  })

  const comparisonObject: Record<string, string[]> = {}
  const uniqueAttributesNames: Set<string> = new Set(attributesNames)
  uniqueAttributesNames.forEach((attributesName) => {
    comparisonObject[attributesName] = []
  })

  newTable.forEach((device) => {
    device[field].forEach((attribute) => {
      comparisonObject[attribute.name].push(attribute.value)
    })
  })

  const fieldsNamesSameValues: Array<string> = []
  for (const key in comparisonObject) {
    if (compareArrayOfStrings(comparisonObject[key])) {
      fieldsNamesSameValues.push(key)
    }
  }

  newTable.forEach((device) => {
    fieldsNamesSameValues.forEach((fieldsNamesSameValue) => {
      device[field].forEach((attribute, index) => {
        if (attribute.name === fieldsNamesSameValue) {
          device[field].splice(index, 1)
        }
      })
    })
  })

  return newTable
}

const sortTableFields = (
  table: Array<DevicesComparisonTableItem>,
  field: string,
) => {
  const newTable: Array<DevicesComparisonTableItem> = _.cloneDeep(table)

  newTable.map((tableItem) => {
    tableItem[field] = tableItem[field].sort(
      (
        a: DevicesComparisonTableItemField,
        b: DevicesComparisonTableItemField,
      ) => (a.name > b.name ? 1 : -1),
    )

    return tableItem
  })

  return newTable
}

const compareArrayOfStrings = (a: string[]) => {
  return !a.some(function (b) {
    return b.toLowerCase() !== a[0].toLowerCase()
  })
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}