import GLightbox from 'glightbox'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      GLightbox,
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $GLightbox: typeof GLightbox
  }
}