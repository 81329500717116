import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

const UTM_TAGS_KEY = 'utmTags'

export const useUtmStore = defineStore('utm', () => {
  const utmTags = ref<{ [key: string]: string }>()
  const initialized = ref<boolean>(false)

  const utmTagsAsString = computed(() => {
    const result = []

    for (const key in utmTags.value) {
      result.push(key + '=' + utmTags.value[key])
    }

    return result.join('&')
  })

  const init = (additionalUtmTags: { [key: string]: string }) => {
    if (initialized.value) return

    loadUtmTags()
    addAdditionalUtmTags(additionalUtmTags)
    initialized.value = true
  }

  const loadUtmTags = () => {
    if (!import.meta.client) return

    const utmTagsFromLocalStorage = localStorage.getItem(UTM_TAGS_KEY)
    if (!utmTagsFromLocalStorage) return

    utmTags.value = JSON.parse(utmTagsFromLocalStorage)
  }

  const addAdditionalUtmTags = (additionalUtmTags: {
    [key: string]: string
  }) => {
    utmTags.value = { ...utmTags.value, ...additionalUtmTags }
    saveUtmTags()
  }

  const saveUtmTags = () => {
    if (!import.meta.client) return

    localStorage.setItem(UTM_TAGS_KEY, JSON.stringify(utmTags.value))
  }

  return {
    utmTags,
    init,
    utmTagsAsString,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUtmStore, import.meta.hot))
}