import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { Organization } from '~/types/models'

class OrganizationsModule extends FetchFactory {
  private readonly RESOURCE = Routes.Organizations

  async getOrganizations() {
    return this.call<Organization[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetOrganiizations()}`,
    })
  }
}

export default OrganizationsModule