import FetchFactory from '../factory'
import Routes from '../routes.client'
import type {
  TinkoffOrderCreationParams,
  TinkoffSuccessOrderCreation,
} from '~/types/models'

class TinkoffModule extends FetchFactory {
  private readonly RESOURCE = Routes.Tinkoff

  async createOrder(params: TinkoffOrderCreationParams) {
    return this.call<TinkoffSuccessOrderCreation>({
      method: 'POST',
      url: `${this.RESOURCE.CreateOrder()}`,
      body: params,
    })
  }
}

export default TinkoffModule