import FetchFactory from '../factory'
import Routes from '../routes.client'
import type { ModelGroup } from '~/types/models'

class ModelGroupsModule extends FetchFactory {
  private readonly RESOURCE = Routes.ModelGroups

  async getModelGroup(slug: string) {
    return this.call<ModelGroup>({
      method: 'GET',
      url: `${this.RESOURCE.GetModelGroup(slug)}`,
    })
  }

  async getModelGroups() {
    return this.call<ModelGroup[]>({
      method: 'GET',
      url: `${this.RESOURCE.GetModelGroups()}`,
    })
  }
}

export default ModelGroupsModule