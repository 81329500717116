<template>
  <div :class="classes.wrapper">
    <div :class="[classes.image, classes.imageWrapper, skeletonStyles]"></div>
    <div>
      <div :class="[classes.name, nameSkeletonClasses]"></div>
      <div :class="[classes.description, classes.descriptionSkeleton]"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { skeletonStyles } from '@/utils'

const nameSkeletonClasses = cx([
  'w-full',
  'h-[42px]',
  'md:h-12',
  'rounded-xl',
  ...skeletonStyles,
])

const classes = {
  wrapper: cx([
    'relative',
    'flex',
    'flex-col',
    'justify-start',
    'w-full',
    'h-full',
    'z-20',
    'rounded-xl',
    'transition',
    'group',
  ]),
  imageWrapper: cx([
    'relative',
    'overflow-hidden',
    'rounded-xl',
  ]),
  image: cx([
    'flex',
    'flex-col',
    'h-[250px]',
    'sm:h-[324px]',
    'bg-cover',
    'bg-no-repeat',
    'bg-center',
    'transition',
    'duration-300',
  ]),
  name: cx([
    'block',
    'font-bold',
    'text-primary',
    'mt-1.5',
    'leading-[14px]',
    'xl:mt-2',
  ]),
  description: cx([
    'text-[12px]/[14px]',
    'text-ghost',
    'xl:text-[16px]/[19px]',
    'my-1.5',
  ]),
  descriptionSkeleton: cx([
    nameSkeletonClasses,
    'h-20',
    'mb-0',
    'xl:h-[85px]',
  ]),
}
</script>